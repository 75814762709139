<template>
  <div class="view">
    <div class="view-body">
      <div
          class="table--wrapper"
          v-if="thead.length > 0"
      >
        <table class="table">
          <thead>
          <!--          <tr>-->
          <transition-group
              name="rowtrans"
              tag="tr"
          >
            <th
                :key="item.key"
                @click="item.sort ? sort(item.key) : false"
                class="table--th-view"
                v-for="item in thead"
            >
              <span
                  :style="[{'cursor': item.sort ? 'pointer' : 'default'}]"
                  class="d-flex align-items-center"
              >
                {{ $t(item.label) }}
                <div class="d-flex flex-column table-sort-icons">
                  <span v-if="item.key == params.column">
                    <font-awesome-icon
                        :class="params.direction == 'asc' ? 'active' : ''"
                        class="caret-sort caret-sort-up"
                        icon="caret-up"
                    />
                    <font-awesome-icon
                        :class="params.direction == 'desc' ? 'active' : ''"
                        class="caret-sort caret-sort-down"
                        icon="caret-down"
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon
                        class="caret-sort caret-sort-up"
                        icon="caret-up"
                    />
                    <font-awesome-icon
                        class="caret-sort caret-sort-down"
                        icon="caret-down"
                    />
                  </span>
                </div>
              </span>
            </th>
          </transition-group>
          <!--          </tr>-->
          </thead>
          <tbody>
          <tr
              :key="key"
              v-for="(row, key) in model"
          >
            <td
                :class="['table_' + item.key,'cursor-pointer table--tbody']"
                :key="key + item.key"
                @click="showModalTask(row)"
                v-for="item in thead"
            >
              <router-link
                  :to="getIssueLink(row.id)"
                  event=""
                  style="display: contents"
              >
                <div v-if="item.key == 'id'">
                  <div class="d-flex">
                      <span
                          :id="'statusColor_' + key + '_' + item.key"
                          :style="[{'background-color' : getStatusColor(row.due_date, row.status_id)}]"
                          style="width: 10px; height: 100%;"
                      ></span>
                    <span style="flex: 1">{{ row[item.key] ? row[item.key] : '' }}</span>
                  </div>
                </div>
                <div v-else-if="item.key == 'priority'">
                    <span v-if="row[item.key]">
                      <span
                          :style="[row.priority_color ? {'color':row.priority_color} : {'color':'#333'}]"
                          style="font-weight: 600;"
                      >{{ row[item.key] }}</span>
                    </span>
                  <span v-else>{{ $t('issue.no_data') }}</span>
                </div>
                <div v-else-if="item.key == 'fullname'">
                  <el-tooltip
                      effect="dark"
                      placement="bottom"
                  >
                    <div slot="content">{{ row.fullname }}</div>
                    <img
                        :src="'data:image/jpeg;base64,'+row.avatar"
                        alt="Avatar"
                        class="avatar"
                        style="border-radius: 50%; width: 35px; height: 35px; cursor: pointer; vertical-align: -0.15em;"
                        v-if="row.avatar"
                    >
                    <svg-icon
                        icon-class="people"
                        style="font-size: 30px; color: #606266"
                        v-else
                    ></svg-icon>
                  </el-tooltip>
                </div>
                <div
                    class="d-flex custom--progress p-1 pl-2"
                    v-else-if="item.key == 'done_ratio'"
                >
                    <span
                        class="d-flex align-items-center"
                        style="width: 100%"
                        v-if="row[item.key]"
                    >
                      <el-progress
                          :color="getStatusColor(row.due_date, row.status_id)"
                          :percentage="row[item.key]"
                          :show-text=false
                          :style="[{'border-color' : getStatusColor(row.due_date, row.status_id)}]"
                      >
                      </el-progress>
                      <span
                          class="p-1"
                          style="min-width: 40px;"
                      >{{ row[item.key] }}%</span>
                    </span>
                  <span
                      class="d-flex align-items-center"
                      style="width: 100%;"
                      v-else
                  >
                      <el-progress
                          :color="getStatusColor(row.due_date, row.status_id)"
                          :percentage="0"
                          :show-text=false
                          :style="[{'border-color' : getStatusColor(row.due_date, row.status_id)}]"
                      ></el-progress>
                      <span
                          class="p-1"
                          style="min-width: 40px;"
                      >0%</span>
                    </span>
                </div>
                <div v-else-if="item.key == 'start_date'">
                  {{ moment(row[item.key]) }}
                </div>
                <div
                    class="pl-2 pr-2"
                    v-else-if="item.key == 'due_date'"
                >
                    <span v-if="moment(row[item.key])">
                      {{ moment(row[item.key]) }}
                      <span
                          :style="[{'color' : getStatusColor(row.due_date, row.status_id)}]"
                          class="pl-1"
                          style="font-weight: 600"
                      >
                        <span v-if="moment() < moment(row[item.key])">
                          <span v-if="days_left(row[item.key]) < systemSetting.days_ago">
                            ({{ days_left(row[item.key]) }} {{ $t('projects.days_left') }})
                          </span>
                          <span v-else>
                            ({{ days_left(row[item.key]) }} {{ $t('projects.days_left') }})
                          </span>
                        </span>
                        <span v-else-if="moment() === moment(row[item.key])"></span>
                        <span v-else>
                          ({{ days_left(row[item.key]) | negativeDate }} {{ $t('projects.days_passed') }})
                        </span>
                      </span>
                    </span>
                  <span v-else></span>
                </div>
                <div v-else-if="item.key == 'status'">
                  <div
                      :style="[{'background-color': row.status_color}]"
                      class="d-flex p-2"
                      v-if="row.status_color"
                  >
                    <span style="color: #fff; font-weight: 600">{{ row[item.key] ? row[item.key] : '' }}</span>
                  </div>
                  <div
                      :style="[{'background-color':'#F2F6FC'}]"
                      class="d-flex p-2"
                      style="font-weight: 600"
                      v-else
                  >
                    <span>{{ row[item.key] ? row[item.key] : '' }}</span>
                  </div>
                </div>
                <div v-else-if="item.key == 'estimated_hours'">
                    <span v-if="row[item.key]">
                      {{ row[item.key] | timeFilter }}
                    </span>
                  <span v-else>
                      {{ $t('issue.no_data') }}
                    </span>
                </div>
                <div v-else>
                    <span style="text-align: left; display: block;">
                      {{ row[item.key] ? row[item.key] : $t('issue.no_data') }}
                    </span>
                </div>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <span class="no-date-title">{{ $t('system.no_selected_column') }}</span>
      </div>
      <div
          v-if="model.length === 0 && thead.length > 0"
          v-loading="loading.table"
      >
        <span class="no-date-title mt-3">{{ $t('system.no_data') }}</span>
      </div>
      <el-card
          class="pagination-container mt-3"
          shadow="never"
          v-if="paginate"
      >
        <el-pagination
            :current-page="params.page"
            :page-size="params.per_page"
            :page-sizes="[10, 30, 50, 100, 300]"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
import moment from 'moment';
import issueMixin from '../../mixin/issue';
import {isObjectsSame} from '../../helpers/functions';

export default {
  name: 'DashboardTable',
  props: ['source', 'thead'],
  mixins: [issueMixin],
  computed: {
    ...mapGetters('issue', ['refreshListIssue']),
    ...mapGetters([
      'systemSetting',
    ]),
  },
  filters: {
    timeFilter: function (date) {
      if (typeof date !== 'undefined' && date != null) {
        return date.slice(0, -3);
      }
      return null;
    },
    negativeDate: function (date) {
      if (typeof date !== 'undefined' && date != null) {
        if (date <= 0) return Math.abs(date);
        return Math.abs(date);
      }
      return null;
    },
  },
  data() {
    return {
      model: [],
      params: {
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      loading: {
        table: true,
      },
      total: 0,
      custom_params: '',
      paginate: true,
    };
  },
  watch: {
    'refreshListIssue'(newValue) {
      if (newValue === true) {
        this.fetchData();
        this.$store.commit('issue/UPDATE_LIST_ISSUE', false);
      }
    },
    params: {
      handler(newVal) {
        this.$router.replace({name: this.$route.name, query: newVal}).catch(() => {
        });
      },
      deep: true,
    },
  },
  beforeMount() {
    if (Object.keys(this.$route.query)) {
      Object.keys(this.$route.query).forEach((key) => {
        const value = this.$route.query[key];
        if (this.params[key]) {
          if (!isNaN(value)) {
            this.params[key] = parseInt(value);
          } else {
            this.params[key] = value;
          }
        }
      });
    }
    this.fetchData();
  },
  beforeDestroy() {
    this.model = [];
    this.total = 0;
    if (!isObjectsSame(this.$route.query, {})) {
      this.$router.replace({name: this.$route.name, query: {}});
    }
  },
  methods: {
    getIssueLink(issueID) {
      return this.$router.currentRoute.path + '?issueId=' + issueID;
    },
    async fetchData() {
      var vm = this;
      vm.loading.table = true;
      vm.url = this.buildURL();
      await axios.get(vm.url, {
        params: {
          ...vm.filters,
        },
      })
          .then(function (response) {
            vm.model = response.data.data;
            vm.total = parseInt(response.data.meta.total);
            vm.loading.table = false;
          })
          .catch(function () {
            vm.loading.table = false;
          });
    },
    buildURL() {
      const p = this.params;
      if (this.paginate) {
        return `${this.source}?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}${this.custom_params}`;
      } else {
        return `${this.source}?column=${p.column}&direction=${p.direction}${this.custom_params}`;
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchData();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchData();
      }
    },
    moment: function (date) {
      if (date !== '0000-00-00 00:00:00' && date !== null) {
        return moment(date).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    sort(column) {
      let direction = null;
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          direction = 'asc';
        } else {
          direction = 'desc';
        }
      } else {
        this.params.column = column;
        direction = 'asc';
      }
      this.params.direction = direction;
      this.fetchData();
    },
    days_left: function (date) {
      return moment(moment(date).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days');
    },
    getStatusColor(date, statusID) {
      let now = moment().format('YYYY-MM-DD');
      let dueDate = moment(date).format('YYYY-MM-DD');
      var diff = this.days_left(date);
      if (now > dueDate) {
        if (this.systemSetting.due_date_color_status.includes(statusID.toString())) {
          return this.systemSetting.due_date_color;
        }
        return 'green';
      }
      if (now < dueDate) {
        if (diff < this.systemSetting.days_ago) {
          if (this.systemSetting.priority_color_status.includes(statusID.toString())) {
            return this.systemSetting.priority_color;
          }
        }
        return 'green';
      }
      if (this.systemSetting && this.systemSetting.issue_ends_today_color_status && this.systemSetting.issue_ends_today_color_status.includes(statusID.toString())) {
        return this.systemSetting.issue_ends_today_color;
      }
      return 'green';
    },
    showModalTask: function (val) {
      const issuesIds = this.model.map((item) => {
        return item.id;
      });
      this.openIssue(val.id, issuesIds);
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import '../../static/css/table.scss';

.hide-text {
  padding-right: 10px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: left;
}

</style>
